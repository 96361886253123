App.Modules = App.Modules || {};
App.Modules.Example = function () {

    var scrollTo = function(data)
    {
        $('.mobile-menu').removeClass('open');
        $('.hamburger').removeClass('open');
        $('body').removeClass('no-scroll');

        var $scrollTarget =  $( $(data.elem).attr('data-scroll-to') );
        if( $scrollTarget.length ) {
            $('html, body').animate({scrollTop: $scrollTarget.offset().top - $('header').outerHeight(true)});
        } else {
            window.location = $(data.elem).attr('href') + '#' + $(data.elem).attr('data-scroll-to');
        }
    };

    var scrollToHash = function(target)
    {
        var $scrollTarget =  $(target);
        if( $scrollTarget.length ) {
            $('html, body').animate({scrollTop: $scrollTarget.offset().top - $('header').outerHeight(true)});
        }
    };

    var checkHash = function()
    {
        if( window.location.hash )
        {
            var target = window.location.hash.substr(1);
            var t = setTimeout( function() {
                scrollToHash(target);
            }, 300);
        }
    };

    return {
        init: function() {
            checkHash();
            return this;
        },
        events: function() {
            Events.bind("click", "[data-scroll-to]").withoutBubble().to(scrollTo, this);
            return this;
        }
    };
}();

