App.Modules = App.Modules || {};
App.Modules.DonateForm = function () {

  var 
    $companyField = $('input[name = "company"]'),
    $entityInput = $('input[name = "entity"]'),

  companyRequiredOrOptional = function(index) {
    var $val = true;
    if ($('input[name = entity]:checked').val() == 'individual') {
      $val = false;
    }
    $companyField.attr('required', $val);
  }

  removeLine = function(index) {
    $(index.elem).parent().parent().remove();
  }

  addLine = function(index) {
    $index = $(index.elem).parent().parent().attr('id').split('_')[1];

    $plus_sign = $('#donation_' + ($index) + ' div').html();

    $('.product-donation').last().clone().attr('id', 'donation_' + $index + 1).insertAfter("#donation_" + ($index));
    $('#donation_' + ($index) + ' svg').remove();
    $('#donation_' + ($index) + ' label').first().remove();

    $('#donation_' + ($index) + ' div').first().append($plus_sign);
    $('#donation_' + ($index ) + ' svg #horizontal').remove();
    $('#donation_' + ($index ) + ' svg').attr('id', 'minus');

    Events.bind('click', '#plus').to(addLine, this);
    Events.bind('click', '#minus').to(removeLine, this);
  }

  updateRecipientOptions = function(index) {
    var select = $('#recipient');
    var disaster_page_id = $('#disaster').val();

    select.children('option:not(:first)').remove();

    var applicant_list = applicants[disaster_page_id];
    $.each(applicant_list, function(k, v) {
      if (v !== 'Greatest Need') {
        select.append("<option value='" + v + "'>" + v + "</option>");
      }
    });
  }

  showGoodsList = function(index) {
    $('.in-kind-container').css('display', 'block');
    $('.show-list-btn').css('display', 'none');
  }

  hideGoodsList = function(index) {
    $('.in-kind-container').css('display', 'none');
    $('.show-list-btn').css('display', 'block');
  }

  setApplicant = function(index) {
    applicant = $('#want-to-donate select').val();
    $('#want-to-donate2 :input#applicant').val(applicant);
  }

  return {
    init: function() {
        return this;
    },
    events: function() {
      Events.bind('change', '#want-to-donate select').to(setApplicant, this);

      Events.bind('click', 'input[name = entity]').to(companyRequiredOrOptional, this);
      Events.bind('click', '#plus').to(addLine, this);
      Events.bind('change', '#disaster').to(updateRecipientOptions, this);
      Events.bind('click', '#show-goods-list').to(showGoodsList, this);
      Events.bind('click', '#hide-goods-list').to(hideGoodsList, this);
      
      return this;
    }
  };
}();