App.Modules = App.Modules || {};
App.Modules.GallerySlider = function () {

  var $slides = $('.gallery-slider img'),
    $largeImages = $('.large-images img'),

  initSlider = function()
  {
    if( $('.gallery-slider').length )
    {
      new Siema({
          selector: '.gallery-slider',
          duration: 200,
          easing: 'ease-out',
          perPage: 4,
          startIndex: 0,
          draggable: true,
          threshold: 20,
          loop: true,
          onInit: function(){},
          onChange: function(){}
      });
    }
  },

  switchSlides = function(data) {
    var $el = $(data.elem);

    $slides.removeClass('active');
    $largeImages.removeClass('active');

    $el.addClass('active');
    $largeImages.eq( $el.index('.gallery-slider img')).addClass('active');
  },

  switchToSlide = function(index)
  {
    $slides.removeClass('active');
    $largeImages.removeClass('active');
    $slides.eq(index).addClass('active');
    $largeImages.eq(index).addClass('active');
  };

  return {
    init: function() {
      //initSlider(); no slider for now
      return this;
    },
    events: function() {
      Events.bind('click', '.gallery-slider img').to(switchSlides, this);

      Events.bind('click', '.gallery-container .prev').to( function() {
        var curIndex = $slides.filter('.active').index('.gallery-slider img');
        switchToSlide(curIndex - 1);
      });

      Events.bind('click', '.gallery-container .next').to( function() {
        var curIndex = $slides.filter('.active').index('.gallery-slider img');
        switchToSlide( (curIndex + 1) > $slides.length - 1 ? 0 : curIndex + 1);
      });
      return this;
    }
  };
}();

