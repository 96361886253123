App.Modules = App.Modules || {};
App.Modules.navMenu = function () {

  var toggleMenu = function(data)
  {
    $('.mobile-menu').toggleClass('open');
    $('.hamburger').toggleClass('open');
    $('body').toggleClass('no-scroll');
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('click', '.hamburger').to(toggleMenu, this);
      Events.bind('click', '.close-btn .fa-close').to(toggleMenu, this);
      return this;
    }
  };
}();

