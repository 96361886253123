App.Modules = App.Modules || {};
App.Modules.EmailAlertForm = function () {

  var formSubmit = function(data) {
    if($('.alert-signup').find('input[type="email"]').val()) {

      var result = $('.alert-signup').find('input[type="email"]').first()[0].checkValidity();

      if (!result) {
        alert("Please enter a valid email address.");
      } else {

        $.ajax({
          url: '/email_alert_signup',
          data: $('#email_alert_form').serialize(),
          type: 'POST',
          complete: function(result)
          {
            $('#thanks-modal-open').click();
          }
        });
      }

    } else {
      alert("Please enter a valid email address.");
    }
  };

  return {
    init: function() { return this; },
    events: function() {
      Events.bind("click", "#email_alert_form_btn").withoutBubble().to(formSubmit, this);
      return this;
    }
  };
}();

