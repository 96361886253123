App.Modules = App.Modules || {};
App.Modules.StripePayments = function () {

  var setup = function()
  {
    pathname = window.location.pathname.split('/');
    last_chunk = pathname[pathname.length - 1];

    if (last_chunk != 'goods') {
      if( $('#card-element').length ) {
        var stripe = Stripe('pk_live_bfc4oQlxn45Ovl3HHizOC0sg');
        var elements = stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        var style = {
          base: {
            // Add your base input styles here. For example:
            fontFamily: '"Archivo", "Helvetica Neue", sans-serif',
            fontSize: '16px'
          }
        };

        // Create an instance of the card Element
        var card = elements.create('cardNumber', {style: style});
        var cardExpiration = elements.create('cardExpiry', {style: style});
        var cardCvc = elements.create('cardCvc', {style: style});

        // Add an instance of the card Element into the `card-element` <div>
        card.mount('#card-element');
        cardExpiration.mount('#card-expiration');
        cardCvc.mount('#card-cvc');

        card.addEventListener('change', function (event) {
          var displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });

        cardExpiration.addEventListener('change', function (event) {
          var displayError = document.getElementById('card-expiration-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });

        cardCvc.addEventListener('change', function (event) {
          var displayError = document.getElementById('card-cvc-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });

        // Create a token or display an error when the form is submitted.
        var form = document.getElementById('payment-form');
        form.addEventListener('submit', function(event) {
          event.preventDefault();

          stripe.createToken(card).then(function(result) {
            if (result.error) {
              // Inform the user if there was an error
              var errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
            } else {
              // Send the token to your server
              stripeTokenHandler(result.token);
            }
          });
        });
      }
    }
  };

  var stripeTokenHandler = function(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = document.getElementById('payment-form');
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);

    // Submit the form

    $('#donate-submit').text('Processing...').attr('disabled','disabled');

    $.ajax({
      url: form.getAttribute('action'),
      type: 'POST',
      data: $(form).serialize(),
      processData: false,
      enctype: 'multipart/form-data',
      success: function(result)
      {
        $('#donation-modal-content').html('<div style="text-align: center; padding: 20vh 2rem;">' + result + '</div>');
        $('body, html').scrollTop(0);
        //$('.donation-modal-intro').last().html('');
        //$('.donation-modal-intro').last().html(result);
      },
      error: function(er)
      {
        $('.donation-modal-intro').last().html('');
        $('.donation-modal-intro').last().html('<p style="color: #8B1820; font-weight: bold;">Oops. Something went wrong... please try again.</p>');
      },
      complete: function()
      {
        $('#donate-submit').text('DONATE').removeAttr('disabled');
      }
    });
  };

  return {
    init: function() {
      setup();
      return this;
    },
    events: function() {
      return this;
    }
  };
}();

