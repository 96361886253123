App.Modules = App.Modules || {};
App.Modules.Modal = function () {

  var $shade = $('.shade');

  var open = function(data) {
    var $modal = $( $(data.elem).attr('data-modal') );
    $('input[name="cause"]').val( $(data.elem).attr('data-cause') );

    $modal.fadeIn();
    $shade.fadeIn(400, function() {
      //
    });
    $('body').addClass('no-scroll');
  };

  var close = function(data) {
    var $clickTarget = $(data.elem);
    if( $clickTarget.hasClass('shade') ||  $clickTarget.hasClass('close') || $clickTarget.hasClass('fa-close') ) {
      $('body').removeClass('no-scroll');
      $('#donation-modal').fadeOut();
      $('#email-alert-thanks-modal').fadeOut();
      $shade.fadeOut(400, function () {
        //
      });
    }
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('click', '[data-modal]').to(open, this);
      Events.bind('click', '.shade, .fa-close').to(close, this, {}, false);
      return this;
    }
  };
}();

