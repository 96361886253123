App.Modules = App.Modules || {};
App.Modules.AboutImageSlider = function () {

  var createSlider = function(selector)
  {
    if( $(selector).length ) {

      $(selector).each( function() {
        $(this).bxSlider({
          controls: true,
          pager: true,
          pagerType: 'full',
          pagerSelector: '',
          minSlides: 1,
          maxSlides: 1,
          moveSlides: 1,
          shrinkItems: true,
          nextSelector: $(this).parent().find('.pager-right .right'),
          nextText: '&#xf054;',
          prevSelector: $(this).parent().find('.pager-left .left'),
          prevText: '&#xf053;',
          mode: 'fade',
          adaptiveHeight: true,
          touchEnabled: false,
          oneToOneTouch: false,
          preventDefaultSwipeY: false,
          onSlideNext: function($slideElement, oldIndex, newIndex)
          {
            $(this).parent().parent().next().parent().find('.pager-middle .page-nums').text( (newIndex + 1) + ' / ' + this.getSlideCount() );
          },
          onSlidePrev: function($slideElement, oldIndex, newIndex)
          {
            $(this).parent().parent().next().parent().find('.pager-middle .page-nums').text( (newIndex + 1) + ' / ' + this.getSlideCount() );
          }
        });
      });

    }
  };

  return {

    init: function() {
      createSlider('.about-image-slider .bxslider');
      return this;
    },
    events: function() {
      return this;
    }
  };
}();

